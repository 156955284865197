import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withFirebase } from "react-redux-firebase";
import { Formik, Form } from "formik";
import InputEmail from "app/components/Input/InputEmail";
import Button from "app/components/Button";
import Loader from "app/components/Loader";
import Alert from "app/components/Alert";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Wrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 1280px;
`;

const InputWrapper = styled.div`
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  resetPassword(email) {
    const { firebase } = this.props;

    this.setState({ loading: true }, () => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => this.setState({ loading: false, success: true }))
        .catch(error => this.setState({ loading: false, error }));
    });
  }

  render() {
    const { error, loading, success } = this.state;

    return (
      <Wrapper>
        <Helmet title="Reset Password | Online Dance Classes and Tutorials" />
        <Container>
          <h1>Reset Password</h1>
          <p>
            Enter your email address and we’ll send you an email with a link to
            reset your password.
          </p>
          {error && (
            <Alert
              variant="danger"
              closeAlert={() => this.setState({ error: null })}
            >
              {error.message} Try again or <Link to="/register">sign up</Link>!
            </Alert>
          )}
          {success && (
            <Alert
              variant="success"
              closeAlert={() => this.setState({ success: null })}
            >
              Password reset email sent successfully! Please check your email
              and <Link to="/login">log in</Link> to change your password.
            </Alert>
          )}
          <Formik
            initialValues={{ email: "" }}
            onSubmit={values => {
              this.resetPassword(values.email);
            }}
            render={({ touched, errors, handleChange, isValid }) => (
              <Form>
                <InputWrapper>
                  <InputEmail
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    required
                  />
                </InputWrapper>
                <ButtonWrapper>
                  <Button type="submit" disabled={!isValid || loading}>
                    {loading ? <Loader width={24} light /> : "Reset Password"}
                  </Button>
                </ButtonWrapper>
              </Form>
            )}
          />
        </Container>
      </Wrapper>
    );
  }
}

PasswordReset.propTypes = {
  firebase: PropTypes.shape({}).isRequired,
};

export default withFirebase(PasswordReset);
